var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-form',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-info px-5",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({name: 'sistema.logAuditoria.lista'})}}},[_vm._v("Voltar")])]},proxy:true}])},[_c('h5',[_vm._v("Log de auditoria ao "+_vm._s(_vm.model.classe)+" de ID "+_vm._s(_vm.model.primaryKey))]),_c('div',{staticClass:"row"},[_c('f-input',{attrs:{"name":"momento","disabled":_vm.disabled,"value":_vm.toStringDatetime(_vm.historicoFoco.momento),"label":"Momento","cols":3}}),_c('f-input',{attrs:{"name":"usuario","disabled":_vm.disabled,"label":"Usuário","cols":3},model:{value:(_vm.historicoFocoEmail),callback:function ($$v) {_vm.historicoFocoEmail=$$v},expression:"historicoFocoEmail"}}),_c('f-input',{attrs:{"name":"evento","disabled":_vm.disabled,"label":"Evento","cols":2},model:{value:(_vm.historicoFoco.evento),callback:function ($$v) {_vm.$set(_vm.historicoFoco, "evento", $$v)},expression:"historicoFoco.evento"}}),_c('f-input',{attrs:{"name":"evento","disabled":_vm.disabled,"label":"IP de acesso","cols":2},model:{value:(_vm.historicoFoco.ipAcesso),callback:function ($$v) {_vm.$set(_vm.historicoFoco, "ipAcesso", $$v)},expression:"historicoFoco.ipAcesso"}}),_c('f-input',{attrs:{"name":"paginacao","disabled":_vm.disabled,"label":"Paginação","cols":2},model:{value:(_vm.paginacao),callback:function ($$v) {_vm.paginacao=$$v},expression:"paginacao"}})],1),_c('div',{staticClass:"row"},[_c('div',{class:{
      'col-6 col-sm-1 order-2 order-sm-1': !_vm.isFirst || !_vm.isLast,
      'd-none': _vm.isFirst && _vm.isLast,
    }},[_c('button',{staticClass:"btn btn-light w-100 h-100",attrs:{"title":"Anterior","disabled":_vm.isFirst},on:{"click":function($event){return _vm.$refs.histSwipeBox.swipetoPrevious()}}},[_c('i',{staticClass:"fas fa-chevron-left"})])]),_c('div',{class:{
      'col-12 col-sm-10 order-1 order-sm-2': !_vm.isFirst || !_vm.isLast,
      'col-12': _vm.isFirst && _vm.isLast,
    }},[_c('swipe-box',{ref:"histSwipeBox",on:{"onChange":function (idx) { return _vm.histIdx = idx; }}},[_c('div',{staticClass:"historicos",staticStyle:{"height":"calc(100vh - 350px)"}},_vm._l((_vm.model.historicos),function(hist,idx){return _c('div',{key:hist.id},[_c('pre',[_vm._v(_vm._s(_vm.processarHistorico(idx)))])])}),0)])],1),_c('div',{class:{
      'col-6 col-sm-1 order-3 order-sm-3': !_vm.isFirst || !_vm.isLast,
      'd-none': _vm.isFirst && _vm.isLast,
    }},[_c('button',{staticClass:"btn btn-light w-100 h-100",attrs:{"title":"Próximo","disabled":_vm.isLast},on:{"click":function($event){return _vm.$refs.histSwipeBox.swipetoNext()}}},[_c('i',{staticClass:"fas fa-chevron-right"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }